@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* .css-1xv8sm6-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  min-width: 200px !important;
  max-width: 100% !important;
} */
